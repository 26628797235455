var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cart-card cursor-pointer p-4 mb-4 mx-4"},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('v-icon',{staticClass:"cart-card__expanded-chevron text-neutral-light me-2",class:{ 'cart-card__expanded-chevron-active': _vm.expanded },attrs:{"size":"3rem"}},[_vm._v(" mdi-chevron-down ")]),_c('img',{staticClass:"me-3",staticStyle:{"height":"4rem"},attrs:{"src":_vm.icon}}),_c('h2',{staticClass:"text-white project__title mb-0"},[_vm._v(_vm._s(_vm.name))]),(_vm.totalPrice)?_c('h3',{staticClass:"text-neutral-light cursor-pointer font-weight-medium ms-auto mb-0"},[_vm._v(" EGP "+_vm._s(_vm.addCommasToNumber(parseFloat(_vm.totalPrice).toFixed(2).toLocaleString()))+" ")]):_vm._e(),(
        _vm.subCategories
          .map(function (sc) { return sc.answers; })
          .flat()
          .every(function (a) { return a.id; })
      )?_c('v-icon',{staticClass:"cart-card__subcategory__delete ms-3 cursor-pointer p-2",attrs:{"color":"#fff","size":"1.5rem"},on:{"click":function($event){$event.stopPropagation();_vm.$emit(
          'delete',
          _vm.subCategories
            .map(function (sc) { return sc.answers; })
            .flat()
            .map(function (a) { return a.id; })
        )}}},[_vm._v(" mdi-close ")]):_vm._e()],1),(_vm.expanded)?_c('div',_vm._l((_vm.subCategories),function(subCategory){return _c('div',{key:subCategory.name},[(subCategory.price > 0)?_c('div',{staticClass:"cart-card__subcategory py-3 mt-5"},[(subCategory.icon)?_c('img',{staticClass:"cart-card__subcategory__icon",attrs:{"alt":subCategory.name,"src":subCategory.icon}}):_vm._e(),_c('h2',{staticClass:"cart-card__subcategory__name d-block text-white font-weight-400 mb-0"},[_vm._v(" "+_vm._s(subCategory.name)+" ")]),_c('h3',{staticClass:"cart-card__subcategory__price mb-0 text-primary-light max-content"},[_vm._v(" EGP "+_vm._s(_vm.addCommasToNumber( parseFloat(subCategory.price).toFixed(2).toLocaleString() ))+" ")]),(_vm.showEdit(subCategory.answers))?_c('v-icon',{staticClass:"cart-card__subcategory__edit ms-3 cursor-pointer p-2",attrs:{"color":"#fff","size":"1.5rem"},on:{"click":function($event){$event.stopPropagation();_vm.$emit(
              'edit',
              subCategory.answers.map(function (a) { return a.question_id; })
            )}}},[_vm._v(" mdi-pencil-outline ")]):_vm._e(),(subCategory.answers.every(function (a) { return a.id; }))?_c('v-icon',{staticClass:"cart-card__subcategory__delete me-3 cursor-pointer p-2",attrs:{"color":"#fff","size":"1.5rem"},on:{"click":function($event){$event.stopPropagation();_vm.$emit(
              'delete',
              subCategory.answers.map(function (a) { return a.id; })
            )}}},[_vm._v(" mdi-close ")]):_vm._e(),_c('div',{staticClass:"cart-card__subcategory__answers"},_vm._l((subCategory.answers.filter(function (a) { return a; })),function(answer){return _c('ul',{key:answer.id,staticClass:"d-flex text-neutral-light"},[_c('li',{staticClass:"h4 font-weight-500 me-3"},[_vm._v(_vm._s(answer.text))])])}),0)],1):_vm._e()])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }