<template>
  <div class="w-100 bg-neutral-dark flex-column px-4 gap-3">
    <div
      @click="p.expanded = !p.expanded"
      v-for="p in projects"
      :key="p.id"
      class="px-4 pcard py-3 mt-4 cursor-pointer"
    >
      <div class="d-flex w-100 justify-content-between mt-3">
        <div class="d-flex">
          <v-icon
            size="3rem"
            class="expanded-chevron text-neutral-light me-2"
            :class="{ 'expanded-chevron-active': p.expanded }"
          >
            mdi-chevron-down
          </v-icon>
          <h2 class="text-neutral-light ms-3">{{ p.name }}</h2>
        </div>

        <h2 class="text-neutral-light d-block font-weight-medium mt-1 mb-0">
          <span class="text-primary-light">Total</span> EGP
          {{ p ? addCommasToNumber(parseFloat(p.total).toFixed(2)) : 0 }}
        </h2>
      </div>
      <div v-if="p.expanded" class="w-100 mt-3">
        <h4 class="text-neutral-light mb-3">Systems:</h4>
        <div class="px-3" v-for="(system, index) in p.systems" :key="index">
          <cart-card
            v-if="system.price > 0"
            :key="index"
            :name="system.name"
            :icon="system.icon"
            :expanded="system.expanded"
            :sub-categories="system.rooms"
            :total-price="system.price"
            @click.native.stop="system.expanded = !system.expanded"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { categories } from "@/utils/constants";
import CartCard from "@/components/CartCard";
import numberFormat from "@/mixins/numberFormat";
import { api } from "@/utils/axios";

export default {
  name: "Projects",
  components: { CartCard },
  mixins: [numberFormat],
  data() {
    return {
      projects: [],
      categories: categories,
    };
  },
  mounted() {
    api
      .get(`${process.env.VUE_APP_BACKEND_BASE_URL}/api/projects`, {
        withCredentials: true,
      })
      .then((response) => {
        this.projects = response.data.data.map((p) => {
          return {
            ...p,
            expanded: false,
          };
        });
        this.projects.forEach((p) => {
          p.systems = this.initializeSystems(p.systems);
        });
      });
  },
  methods: {
    initializeSystems(systems) {
      return systems.map((s) => {
        s.rooms.map((r) => {
          r.icon = require(`../assets/icons/room-types/${r.typeName.replaceAll(
            " ",
            "-"
          )}.png`);
        });

        s.icon = require(`../assets/icons/Dashboard/${
          this.categories[s.name].icon
        }.png`);
        s.name = this.categories[s.name].name;

        return {
          ...s,
          expanded: false,
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
.pcard {
  border: 2px solid var(--neutral-light);
  border-radius: 0.5rem;
  transition: height 500ms linear;
}

.expanded-chevron {
  border: 2px solid var(--neutral-light);
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  margin-right: 30px !important;

  &-active {
    transform: rotate(180deg);
  }
}
</style>
