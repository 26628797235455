export default {
  methods: {
    addCommasToNumber(x) {
      // Adds commas between thousand places in a number
      if (x !== null && x !== "NaN")
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "0.00";
    },
    getFormattedPrice(x) {
      return (
        this.addCommasToNumber(parseFloat(x).toFixed(2).toLocaleString()) || 0
      );
    },
  },
};
