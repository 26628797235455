<template>
  <div class="cart-card cursor-pointer p-4 mb-4 mx-4">
    <div class="d-flex flex-row align-items-center">
      <v-icon
        size="3rem"
        class="cart-card__expanded-chevron text-neutral-light me-2"
        :class="{ 'cart-card__expanded-chevron-active': expanded }"
      >
        mdi-chevron-down
      </v-icon>
      <img :src="icon" class="me-3" style="height: 4rem" />
      <h2 class="text-white project__title mb-0">{{ name }}</h2>
      <h3
        v-if="totalPrice"
        class="text-neutral-light cursor-pointer font-weight-medium ms-auto mb-0"
      >
        EGP
        {{
          addCommasToNumber(parseFloat(totalPrice).toFixed(2).toLocaleString())
        }}
      </h3>
      <v-icon
        v-if="
          subCategories
            .map((sc) => sc.answers)
            .flat()
            .every((a) => a.id)
        "
        color="#fff"
        size="1.5rem"
        class="cart-card__subcategory__delete ms-3 cursor-pointer p-2"
        @click.stop="
          $emit(
            'delete',
            subCategories
              .map((sc) => sc.answers)
              .flat()
              .map((a) => a.id)
          )
        "
      >
        mdi-close
      </v-icon>
    </div>

    <div v-if="expanded">
      <div v-for="subCategory in subCategories" :key="subCategory.name">
        <div
          v-if="subCategory.price > 0"
          class="cart-card__subcategory py-3 mt-5"
        >
          <img
            v-if="subCategory.icon"
            class="cart-card__subcategory__icon"
            :alt="subCategory.name"
            :src="subCategory.icon"
          />

          <h2
            class="cart-card__subcategory__name d-block text-white font-weight-400 mb-0"
          >
            {{ subCategory.name }}
          </h2>

          <h3
            class="cart-card__subcategory__price mb-0 text-primary-light max-content"
          >
            EGP
            {{
              addCommasToNumber(
                parseFloat(subCategory.price).toFixed(2).toLocaleString()
              )
            }}
          </h3>

          <v-icon
            v-if="showEdit(subCategory.answers)"
            color="#fff"
            size="1.5rem"
            class="cart-card__subcategory__edit ms-3 cursor-pointer p-2"
            @click.stop="
              $emit(
                'edit',
                subCategory.answers.map((a) => a.question_id)
              )
            "
          >
            mdi-pencil-outline
          </v-icon>
          <v-icon
            v-if="subCategory.answers.every((a) => a.id)"
            color="#fff"
            size="1.5rem"
            class="cart-card__subcategory__delete me-3 cursor-pointer p-2"
            @click.stop="
              $emit(
                'delete',
                subCategory.answers.map((a) => a.id)
              )
            "
          >
            mdi-close
          </v-icon>

          <div class="cart-card__subcategory__answers">
            <ul
              class="d-flex text-neutral-light"
              v-for="answer in subCategory.answers.filter((a) => a)"
              :key="answer.id"
            >
              <li class="h4 font-weight-500 me-3">{{ answer.text }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numberFormat from "@/mixins/numberFormat";

export default {
  name: "CartCard",
  mixins: [numberFormat],
  props: {
    name: String,
    expanded: Boolean,
    icon: String,
    totalPrice: Number,
    subCategories: Array,
  },
  methods: {
    showEdit(answers) {
      if (!answers) {
        return false;
      }
      let wizardQuestionsIds = this.$store.state.wizard.wizardSteps
        .map((s) => s.questions)
        .flat()
        .map((q) => q.id);
      return (
        answers.every((a) => a.id) &&
        answers
          .map((a) => a.question_id)
          .every((qid) => wizardQuestionsIds.includes(qid))
      );
    },
  },
};
</script>

<style scoped lang="scss">
.cart-card {
  border: 2px solid var(--neutral-light);
  border-radius: 0.5rem;
  transition: height 500ms linear;

  &__expanded-chevron {
    border: 2px solid var(--neutral-light);
    border-radius: 50%;
    transition: transform 200ms ease-in-out;
    margin-right: 30px !important;

    &-active {
      transform: rotate(180deg);
    }
  }

  &__subcategory {
    width: 100%;
    display: grid;
    grid-template-columns: 0.5fr 5fr 1fr 0.25fr 0.25fr;
    grid-template-areas:
      "icon name price edit delete"
      ". answers . . .";
    gap: 0.5rem;
    color: var(--neutral-light);
    align-items: center;
    border: 3px solid var(--primary-light);
    border-radius: 0.5rem;

    &__icon {
      grid-area: icon;
      height: 3.5rem;
      justify-self: center;
    }

    &__name {
      grid-area: name;
    }

    &__price {
      grid-area: price;
    }

    &__answers {
      grid-area: answers;
    }

    &__delete {
      grid-area: delete;
      border: 2px solid var(--neutral-light);
      border-radius: 50%;
    }

    &__edit {
      grid-area: edit;
      border: 2px solid var(--neutral-light);
      border-radius: 50%;
    }
  }
}
</style>
